<template>
    <div>
      <CRow class="home-section-one">
        <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2+"; padding: 15px;"'>
          <h3 style="text-transform: uppercase;">
            {{raffle.name}}
            <br />
            {{raffle.prize}}
            <br />
            {{raffle.date | date}}
          </h3>
        </CCol>
        <CCol md="3"></CCol>
        <CCol md="6">
          <br />
          <CCard style="margin: 0px;">
            <CCardBody style="padding: 0px;">
              <CCarousel
                arrows  
                animate
                :interval="5000"
              > 
                <CCarouselItem :key="i" v-for="(image, i) in raffle.images.award" :image="image.filename" />
              </CCarousel>
            </CCardBody>
          </CCard>
          <br />
        </CCol>      
        <CCol md="3"></CCol>        
      </CRow>
      <CRow class="home-section-one">
        <CCol class="home-subtitle" sm="12" :style='"padding: 15px;"'>
          <h3 :style='"text-transform: uppercase; color: red;"'>Con tu boleto liquidado estar participando por:</h3>
        </CCol>   
      </CRow>
      <CRow class="home-section-one">
        <CCol class="home-title" sm="12" :style='"padding: 15px; color: black;"'>
          {{raffle.prize}}
        </CCol>   
      </CRow>
      <CRow class="home-section-one">
        <CCol sm="12" :style='"padding: 15px; text-align: center; color: black;"'>
          <h1 :style='"text-transform: uppercase; color: black;"'>2do Premio: $ 100,000.00</h1>
          <h1 :style='"text-transform: uppercase; color: black;"'>3er Premio: Iphone 15 PRO y PS5</h1>
        </CCol>   
      </CRow>
      <CRow class="home-section-one">
        <CCol class="home-subtitle" sm="12" :style='"padding: 15px;"'>
          <h4 :style='"text-transform: uppercase; color: red;"'>Presorteo: Martes, 25 Junio 2024<br /><br />Pagando en las primeras 8 horas de apartar los boletos de tu compra.<br /><br /></h4>
          <h1 :style='"text-transform: uppercase; color: black;"'>$ 20,000.00</h1>
        </CCol>   
      </CRow>
      <!--
        <CRow id="bonos" ref="bonos" class="home-section-one">
          <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
            <h1>BONOS</h1>
          </CCol>
          <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
            <h3>Con tu boleto liquidado participas por: EDICIÓN N°117 - 5 ABRIL</h3>
            <br>
            <h3>Con tu boleto liquidado participas por: EDICIÓN N°117 - 5 ABRIL</h3>
            <br>
            <h3>Con tu boleto liquidado participas por: EDICIÓN N°117 - 5 ABRIL</h3>
            <br>
            <h3>Con tu boleto liquidado participas por: EDICIÓN N°117 - 5 ABRIL</h3>
            <br>
            <h3>Con tu boleto liquidado participas por: EDICIÓN N°117 - 5 ABRIL</h3>
            <br>
          </CCol>
        </CRow> 
        -->
    </div>
</template>

<script>


export default {
  name: 'Raffle',
  props: {
    raffle: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      title: "Vista de la Rifa"
    }
  },
  mounted: async function() {
      
  },
  methods: {
    
  }
}
</script>
<style scoped>
.home-section-one{
  margin: 0px;
}
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    min-height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.carousel-caption{
  color:#000  !important;
}
.row{
  margin:0px;
}
</style>
