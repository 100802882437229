<template>
  <div class="c-app"> 
    <div v-if="!has_raffles">{{no_raffles_label}}</div>
    <CWrapper v-if="raffle">
      <TheHeader :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
      <div class="c-body" id="inicio" ref="inicio">
        <main class="c-main">
          <CContainer fluid class="p-0">
            <Raffle :raffle="raffle" />
            <CRow id="preguntas" ref="preguntas" class="home-section-one">
              <CCol class="home-title" sm="12" :style='"background-color: "+raffle.color_2'>
                <h3>PREGUNTAS FRECUENTES</h3>
              </CCol>
              <CCol sm="12" style="height: 20px;"></CCol>
            </CRow>
            <CRow>
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <h3>¿CÓMO SE ELIGE A LOS GANADORES?</h3>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="3"></CCol>
              <CCol sm="6">
                <p class="text-center">
                  Todos nuestros sorteos se realizan en base a la 
                  <a href="https://www.lotenal.gob.mx" rel="noopener noreferrer" target="_blank" style="color: rgb(61, 155, 233);">
                    <u>Lotería Nacional para la Asistencia Pública</u>
                  </a>
                  mexicana.
                </p>
                <p class="text-center">El ganador de Rifas Jalisco Zona Valles será el participante cuyo número de boleto coincida con las últimas cifras del primer premio ganador de la Lotería Nacional (las fechas serán publicadas en nuestra página oficial).</p>
              </CCol>
              <CCol sm="3"></CCol>
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <h3>¿QUÉ SUCEDE CUANDO EL NÚMERO GANADOR ES UN BOLETO NO VENDIDO?</h3>
              </CCol>
              <CCol sm="3"></CCol>
              <CCol sm="6">
                <p class="text-center">
                  Se elige un nuevo ganador realizando la misma dinámica en otra fecha cercana (se anunciará la nueva fecha).

                  Esto significa que, ¡Tendrías el doble de oportunidades de ganar con tu mismo boleto!
                </p>                
              </CCol>
              <CCol sm="3"></CCol>
              <CCol class="home-subtitle" sm="12" :style='"color: "+raffle.color_2'>
                <h3>¿DÓNDE SE PUBLICA A LOS GANADORES?</h3>
              </CCol>
              <CCol sm="3"></CCol>
              <CCol sm="6">
                <p class="text-center">
                  En nuestra página oficial de Facebook Rifas Jalisco puedes encontrar todos y cada uno de nuestros sorteos anteriores, así como las transmisiones en vivo con Lotería Nacional y las entregas de premios a los ganadores!
                </p>
                <p class="text-center">
                  Encuentra transmisión en vivo de los sorteos en nuestra página de Facebook en las fechas indicadas a las 20:00 hrs CDMX. ¡No te lo pierdas!
                </p>
              </CCol>    
              <CCol sm="3"></CCol>        
              <CCol sm="12" style="height: 20px;"></CCol>
            </CRow>   
            <Sections :raffle="raffle" />      
          </CContainer>
        </main>
      </div>
      <TheFooter :c1="raffle.color_1" :c2="raffle.color_2" @anchor="anchor"/>
    </CWrapper>
    <loading :active="visible" :can-cancel="true"></loading>
    <CToaster placement="top-end">
      <template v-for="(toast, key) in toasts">
        <CToast :color="toast.color" v-bind="toast" :key="'toast' + key" :show="true">
          {{toast.content}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import TheHeader from '../../containers/TheHeaderLanding'
import TheFooter from '../../containers/TheFooterLanding'
import Raffle from '../../components/application/Raffle'
import Sections from '../../components/application/Sections'
import store from '../../store'
import Vue from 'vue';
import Loading from 'vue-loading-overlay/dist/vue-loading.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import ws from '../../services/raffles';


export default {
  name: 'Landing',
  components: {
    TheHeader,
    TheFooter,
    Loading,
    Raffle,
    Sections
  },
  data() {
      return {
          code: '',
          has_raffles: true,
          no_raffles_label: "",
          raffle: false,
          color_1: false,
          color_2: false
      }
  },
  mounted: async function() {
    this.loading();
      let anchor_id = this.$route.query.anchor;

      this.code = await this.$route.params.code;

      let response = false;
      if(this.code === undefined){
        response = await ws.getMainRaffle();  
      }
      else{
        response = await ws.getRaffle(this.code);  
      }

      if(response.type == "success"){
          this.raffle = response.data;
      }
      else{
          if(this.code !== undefined){
            this.loaded(); 
            window.location.href = "/";
            return;
          }

          this.no_raffles_label = response.message;
          this.has_raffles = false;
      }

      if(anchor_id !== undefined){
        this.anchor(anchor_id);
      }
    this.loaded();
  }, 
  computed: {
    toasts () {
      return store.state.toasts;
    },
    visible() {
      return store.state.isLoading;
    }
  },
  methods: {
    async anchor(anchor_id){
      let anchor = false;

      switch(anchor_id){
        case 'nosotros': anchor = await this.$refs.nosotros; break;
        case 'preguntas': anchor = await this.$refs.preguntas; break;
        case 'contacto': anchor = await this.$refs.contacto; break;
        case 'garantia': anchor = await this.$refs.garantia; break;
        case 'inicio': anchor = await this.$refs.inicio; break;
        case 'tickets': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/boletos'})
          return;
        }
        case 'payments': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/pagos'})
          return;
        }
        case 'terms': {
          this.$router.push({path: (this.code !== undefined ? '/'+this.code : '')+'/aviso-de-privacidad'})
          return;
        }
      }

      var element = await document.getElementById(anchor_id);
      var headerOffset = 160;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
<style scoped>
.home-section-one{
  margin: 0px;
}
.home-title{
    display: flex;
    width: 100%;
    color: #fff;
    height: 80px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 54px;
    text-shadow: 1px 3px 5px #000;
    align-items: center;
    justify-content: center;
}
h1, h3{
  font-weight: bolder;
}
.home-subtitle{
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 3px 5px #AAA;
  font-family: "Lato", sans-serif;
}
.home-subtitle a{
  text-align: center !important;
  margin-top: 10px !important;
  text-shadow: 1px 3px 5px #AAA !important;
  font-family: "Lato", sans-serif !important;
}
.home-icon a{
  display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    line-height: normal;
    height: auto;
    min-height: 20px;
    min-width: 20px;
    overflow: hidden;
    margin: 0 5px 0 -1px;
    padding: 0 24px;
    cursor: pointer;
    pointer-events: auto;
}
.c-body{
  font-family: "Lato", sans-serif !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.carousel-caption{
  color:#000  !important;
}
.row{
  margin:0px;
}
</style>
